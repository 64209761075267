import styled from 'styled-components';
import {
  ButtonDefault,
  ButtonGhost,
  ButtonInverted
} from 'components/Atoms/Buttons';
import Spinner from 'components/Atoms/Spinner';

const BUTTON_VARIANTS = Object.freeze({
  default: ButtonDefault,
  inverted: ButtonInverted,
  ghost: ButtonGhost
});

const Button = ({
  variant = 'default',
  isLoading,
  loadingText,
  icon,
  uiTheme,
  isFluid,
  children,
  isDisabled,
  onClick,
  type
}) => {
  const RenderedButton = BUTTON_VARIANTS[variant];

  return (
    <RenderedButton
      onClick={onClick}
      uiTheme={uiTheme}
      isFluid={isFluid}
      disabled={isDisabled}
      isLoading={isLoading}
      type={type}
    >
      {(icon || isLoading) && (
        <IconContainer data-testid={'btn-icon'}>
          {isLoading ? <Spinner size={16} data-testid={'spinner'} /> : icon}
        </IconContainer>
      )}
      {isLoading ? `${loadingText || children}` : children}
    </RenderedButton>
  );
};

export default Button;

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: center;
  margin-right: 8px;
  width: 16px;
  svg {
    fill: currentColor;
  }
`;
