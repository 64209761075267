import styled, { css } from 'styled-components';

const isLoadingStyles = css`
  opacity: 0.75;
  pointer-events: none;
`;

const ButtonBase = styled.button`
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  color: ${({ color }) => `var(--color-${color})`};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  justify-content: center;
  letter-spacing: 1px;
  padding: 8px 22px;
  text-align: center;
  text-transform: uppercase;
  transition: 200ms all;
  white-space: nowrap;
  width: ${({ isFluid }) => (isFluid ? '100%' : 'fit-content')};
  ${({ isLoading }) => isLoading && isLoadingStyles}
  &:hover {
    box-shadow: ${({ uiTheme }) => `var(--color-${uiTheme}-opaque)`} 0px 3px 8px;
  }
  &:active {
    box-shadow: ${({ uiTheme }) => `var(--color-${uiTheme}-opaque)`} 0px 1px 4px;
    transform: scale(0.97);
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }
  &:focus-visible {
    box-shadow: ${({ uiTheme }) => `var(--color-${uiTheme}-opaque)`} 0px 3px 8px;
    outline: 2px solid var(--color-system-info);
  }
`;

export const ButtonDefault = styled(ButtonBase)`
  background: ${({ uiTheme }) =>
    `var(--color-${uiTheme || 'primary'}-default)`};
  border: 1px solid
    ${({ uiTheme }) => `var(--color-${uiTheme || 'primary'}-default)`};
  color: ${({ uiTheme }) =>
    `var(--color-neutral-${
      uiTheme === 'danger' || uiTheme === 'neutral' ? 'white' : 'dark'
    })`};
  &:hover {
    background: ${({ uiTheme }) => `var(--color-${uiTheme || 'primary'}-dark)`};
    border: 1px solid
      ${({ uiTheme }) => `var(--color-${uiTheme || 'primary'}-dark)`};
  }
`;

export const ButtonInverted = styled(ButtonBase)`
  background: transparent;
  border: ${({ uiTheme }) => `1px solid var(--color-${uiTheme}-default)`};
  color: ${({ uiTheme }) => `var(--color-${uiTheme}-default)`};
  &:hover {
    border: 1px solid var(--color-danger-dark);
    color: var(--color-danger-dark);
    ${({ uiTheme }) =>
      uiTheme === 'danger'
        ? 'border: 1px solid var(--color-danger-dark) color: var(--color-danger-dark);'
        : 'background: var(--color-neutral-10); border: 1px solid var(--color-neutral-default); color: var(--color-neutral-dark);'}
  }
`;

export const ButtonGhost = styled(ButtonBase)`
  background: transparent;
  color: var(--color-neutral-light);
  &:hover {
    background: var(--color-neutral-10);
    color: var(--color-neutral-60);
  }
`;
