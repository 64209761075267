import 'styles/normalize.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';

import App from 'pages/App/App';
import GlobalStyles from 'styles/globalStyles';
import ErrorPage from 'components/Blocks/ErrorBoundary/ErrorPage';
import { name, version } from '../package.json';

console.log('You are using Correlation One ' + name + ' version: ' + version);

const queryClient = new QueryClient();

Sentry.init({
  dsn: 'https://292cb53b5b70495baf53497066c35e34@o1174160.ingest.sentry.io/6269953',
  integrations: [
    new BrowserTracing(),
    new CaptureConsole({
      levels: ['error']
    })
  ],
  environment: process.env.REACT_APP_ENVIRONMENT,
  enabled: process.env.NODE_ENV === 'production',
  release: version,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <QueryClientProvider client={queryClient}>
        <GlobalStyles />
        <Sentry.ErrorBoundary fallback={ErrorPage}>
          <App />
        </Sentry.ErrorBoundary>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
