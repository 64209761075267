import React from 'react';
import { createGlobalStyle } from 'styled-components';
import Notifications, { notify } from 'react-notify-toast';
import { ReactComponent as ErrorIcon } from 'assets/icons/notify-error-icon.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/notify-success-icon.svg';

const show = notify.createShowQueue();

export const NotificationOverride = createGlobalStyle`
  .toast-notification {
    left: 0 !important;
    margin: auto !important;
    pointer-events: none;
    position: fixed;
    right: 0 !important;
    text-align: left !important;
    top: 140px !important;
    transform: translateY(0px);
    transition: all 300ms ease 0s;
    width: max-content !important;
    z-index: 200;
  }
  .toast-notification span{
    border-radius: 0 !important;
    padding: 0 !important;
  }
  .notification {
    align-items: center;
    border-radius: 18px !important;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    gap: 8px;
    letter-spacing: 0.15px;
    line-height: 24px;
    margin:auto;
    max-width: 650px;
    padding: 6px 14px 6px 14px !important;
    text-align: left;
  }
  .notification.successNotification {
    background: rgba(110, 188, 150, .2);
    box-sizing: border-box;
    color: var(--color-success-dark);
  }
  .notification.errorNotification {
    background: rgba(213, 78, 76, .2);
    box-sizing: border-box;
    color: var(--color-danger-default);
  }
`;

export const NotificationContainer = () => {
  return (
    <>
      <NotificationOverride />
      <Notifications />
    </>
  );
};

const customColor = {
  background: 'transparent',
  text: '#000000'
};

export const Notification = {
  success: props => {
    const { notifyInfo } = props;
    show(
      <div className="notification successNotification">
        <SuccessIcon />
        {notifyInfo}
      </div>,
      'custom',
      1500,
      customColor
    );
  },
  error: props => {
    const { notifyInfo } = props;
    notify.show(
      <div className="notification errorNotification">
        <ErrorIcon />
        {notifyInfo}
      </div>,
      'custom',
      5000,
      customColor
    );
  }
};
